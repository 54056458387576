import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Button from "react-bootstrap/Button"

import {
  BsPinMap,
  BsInstagram,
  BsTelephone,
  BsEnvelopeAt,
  BsLinkedin,
  BsFileEarmarkPdfFill,
} from "react-icons/bs"

import TCFooter from "../Utils/TCFooter"

import probe from "../video/probe.mp4"

import capabilites from "../files/Tough_Cut_Capabilities.pdf"

import "../css/home.css"

const HomeHeader = () => (
  <Row>
    <Col>
      <div className="home-video-container">
        <video playsInline autoPlay muted loop className="home-video">
          <source src={probe} type="video/mp4" />
        </video>
        <div className="home-video-overlay-header">
          <h1>Precision CNC and Additive Manufacturing</h1>
          <Button className="link-button" href="/about">
            Learn More
          </Button>
        </div>
      </div>
    </Col>
  </Row>
)

const HomeInfo = () => {
  const homeContactInfoItems = {
    address: {
      icon: <BsPinMap className="contact-info-icon" />,
      title: "Address:",
      info: (
        <a href="http://maps.apple.com/?q=2772+E+Miraloma+Ave,+Anaheim+CA">
          2772 E. Miraloma Ave.
          <br />
          Anaheim, CA 92806
        </a>
      ),
    },
    phone: {
      icon: <BsTelephone className="contact-info-icon" />,
      title: "Phone:",
      info: <a href="tel:+17142879736">+1 (714) 287-9736</a>,
    },
    email: {
      icon: <BsEnvelopeAt className="contact-info-icon" />,
      title: "Email:",
      info: <a href="mailto:sales@touchcutmfg.com">sales@toughcutmfg.com</a>,
    },
  }

  return (
    <Row id="contact">
      <Col>
        <div id="home-info">
          <Container>
            <Row id="home-info-row">
              <Col sm={12} md={6} id="home-info-col">
                <div id="contact-info">
                  <h1>Contact Info</h1>
                  {Object.keys(homeContactInfoItems).map((key) => (
                    <div key={key} className="contact-info-container">
                      {homeContactInfoItems[key].icon}
                      <div className="contact-info-information">
                        <strong>{homeContactInfoItems[key].title}</strong>
                        {homeContactInfoItems[key].info}
                      </div>
                    </div>
                  ))}
                </div>
                <iframe
                  title="Tough Cut Map"
                  id="home-contact-map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3313.364823986789!2d-117.87563612461005!3d33.85448762814707!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcd75b5105f53b%3A0xd86fe2784716ec6e!2sTough%20Cut%20Manufacturing!5e0!3m2!1sen!2sus!4v1704241196990!5m2!1sen!2sus"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                />
                <div id="home-social-media-info">
                  <h1>Follow Us</h1>
                  <div className="home-social-media-icons">
                    <a href="https://www.linkedin.com/company/tough-cut-manufacturing">
                      <BsLinkedin className="home-social-media-icon" />
                    </a>
                    <a href="https://www.instagram.com/toughcutmfg/">
                      <BsInstagram className="home-social-media-icon" />
                    </a>
                  </div>
                </div>
                <div id="home-download-info">
                  <h1>Available Downloads</h1>
                  <div id="home-download-info-container">
                    <a
                      href={capabilites}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <BsFileEarmarkPdfFill id="home-download-info-icon" />
                      <div id="home-download-info-title">
                        Tough Cut Capability Summary
                      </div>
                    </a>
                  </div>
                </div>
              </Col>
              <Col id="home-about-us-col">
                <div id="home-about-us-info-container">
                  <h1>About Us</h1>
                  <p align="justify">
                    Welcome to Tough Cut Manufacturing! We specialize in
                    producing high-quality, custom components to meet the unique
                    needs of our clients.
                  </p>
                  <p align="justify">
                    Using state-of-the-art CNC machines and advanced programming
                    software, we are able to create precise, complex parts with
                    exceptional accuracy and consistency. Our team of skilled
                    technicians is dedicated to providing personalized service
                    and ensuring that every component we produce meets our
                    clients' exact specifications.
                  </p>
                  <p align="justify">
                    Whether you need a single prototype or large-scale
                    production run, we have the expertise and capabilities to
                    deliver exceptional results. Our commitment to quality,
                    reliability, and customer satisfaction has earned us a
                    reputation as a trusted partner for businesses in a wide
                    range of industries.
                  </p>
                  <p align="justify">
                    We are always looking for new challenges and opportunities
                    to help our clients succeed. Contact us today to learn more
                    about how we can help you bring your ideas to life!
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <TCFooter pageId="home-page" />
            </Row>
          </Container>
        </div>
      </Col>
    </Row>
  )
}

const Home = () => (
  <div id="home-page">
    <Container fluid className="p-0">
      <HomeHeader />
      <HomeInfo />
    </Container>
  </div>
)

export default Home
