import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import App from "./App.jsx"
import "bootstrap/dist/css/bootstrap.min.css"

const domNode = document.getElementById("root")
const root = createRoot(domNode)
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
)
