import { useEffect } from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import TCFooter from "../Utils/TCFooter"
import Image from "react-bootstrap/Image"
import Card from "react-bootstrap/Card"

import pyramid from "../img/pyramid.jpg"
import shopfloor from "../img/shop_floor.png"
import oscar from "../img/oscar1.jpeg"
import breeland from "../img/breeland1.jpeg"
import kyler from "../img/kyler1.png"
import hugo from "../img/hugo1.jpeg"

import mill from "../video/mill.mp4"
import indicator from "../video/indicator.mp4"
import team from "../video/team.mp4"

import "../css/about.css"

const AboutOverview = () => (
  <Row>
    <Col>
      <div className="about-video-container">
        <video
          playsInline
          autoPlay
          muted
          loop
          id="overview-video"
          className="about-video"
        >
          <source src={mill} type="video/mp4" />
        </video>
        <div id="overview-header" className="about-video-overlay-header">
          <h1>Tough Cut Manufacturing</h1>
          <h2>Crafting the Future Through Machining</h2>
        </div>
        <div id="overview-section" className="about-section">
          <Row xs={1} sm={1} md={1} lg={2} id="overview">
            <Col className="about-section-header">
              <h1>Our Story</h1>
              <Image className="about-section-img" src={pyramid} alt="" fluid />
            </Col>
            <Col className="about-section-content">
              <div id="overview-section-content">
                <p align="justify">
                  Our story begins with a group of high school classmates who
                  shared a passion for engineering. United by a common vision,
                  we embarked on a journey to establish a company that would
                  redefine aerospace manufacturing. Today, we are a leading
                  force in Southern California, specializing in CNC machining
                  and 3D printing services.
                </p>
                <p align="justify">
                  Driven by our engineering expertise and a commitment to
                  excellence, Tough Cut Manufacturing has become synonymous with
                  precision and innovation. With our founders' deep
                  understanding of the industry, we have crafted a company that
                  consistently delivers exceptional results. Our team's diverse
                  skill sets and years of experience enable us to tackle even
                  the most complex aerospace manufacturing challenges.
                </p>
                <p align="justify">
                  Beyond our technical prowess, what sets us apart is our shared
                  history. As classmates, we have a deep bond rooted in
                  friendship and trust. This camaraderie fuels our collaboration
                  and drives us to exceed expectations. We understand the unique
                  needs of our clients and approach every project with
                  dedication, attention to detail, and a relentless pursuit of
                  perfection.
                </p>
                <p align="justify">
                  At Tough Cut Manufacturing, we pride ourselves on being a
                  trusted partner for aerospace companies seeking cutting-edge
                  manufacturing solutions. Our "can-do" attitude, combined with
                  our founders' shared passion for engineering, propels us to
                  push the boundaries of what's possible in aerospace
                  manufacturing. Together, we continue to shape the future of
                  the industry, one precise component at a time.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Col>
  </Row>
)

const AboutTeam = () => (
  <div id="team">
    <Row>
      <Col>
        <div className="about-video-container">
          <video
            playsInline
            autoPlay
            muted
            loop
            id="team-video"
            className="about-video"
          >
            <source src={team} type="video/mp4" />
          </video>
        </div>
        <div id="team-header" className="about-video-overlay-header">
          <h1>Our Team</h1>
        </div>
        <div id="team-section" className="about-section">
          <Row xs={1} sm={1} md={2} className="about-team-section">
            <Col className="about-section-header">
              <Image
                src={oscar}
                alt="Oscar Becerra"
                fluid
                className="about-team-img"
              />
              <h1>Oscar Becerra</h1>
              <h2>Chief Executive Officer</h2>
            </Col>
            <Col className="about-section-content">
              <p>
                With a lifelong passion for engineering and CNC machining
                experience dating back to his high school days, Oscar has
                leveraged years of industry experience to establish his own
                machine shop, blending aerospace expertise with precision
                machining to make a significant mark in the field.
              </p>
            </Col>
          </Row>

          <Row
            xs={1}
            sm={1}
            md={2}
            className="about-team-section secondary-section"
          >
            <Col className="about-section-header">
              <Image
                src={breeland}
                alt="Breeland Abrams"
                fluid
                className="about-team-img"
              />
              <h1>Breeland Abrams</h1>
              <h2>Chief Financial Officer</h2>
            </Col>
            <Col className="about-section-content">
              <p>
                Coming from a computer science background, Breeland has
                translated his technological skills into the world of machining
                and business. Drawing on his high school experiences with CNC
                machning and subsequent industry expertise, he established his
                own machining business, fusing technology and precision
                engineering to carve a unique niche in the industry.
              </p>
            </Col>
          </Row>
          <Row
            xs={1}
            sm={1}
            md={2}
            className="about-team-section secondary-section"
          >
            <Col className="about-section-header">
              <Image
                src={hugo}
                alt="Hugo Hernandez"
                fluid
                className="about-team-img"
              />
              <h1>Hugo Hernandez</h1>
              <h2>President - Quality Engineering</h2>
            </Col>
            <Col className="about-section-content">
              <p>
                With a strong academic background in the field, Hugo ventured
                into aerospace engineering, accumulating valuable experience
                before embarking on an entrepreneurial journey. After several
                years in the industry, Hugo established his own machine shop,
                seamlessly combining aerospace knowledge with business acumen,
                and hopes to leave an indelible mark in the field.
              </p>
            </Col>
          </Row>
          <Row
            xs={1}
            sm={1}
            md={2}
            className="about-team-section secondary-section"
          >
            <Col className="about-section-header">
              <Image
                src={kyler}
                alt="Kyler Harvey"
                fluid
                className="about-team-img"
              />
              <h1>Kyler Harvey</h1>
              <h2>VP - Manufacturing Engineering</h2>
            </Col>
            <Col className="about-section-content">
              <p>
                Kyler has been shaping his engineering prowess since high school
                where he gained his first hands on experience with CNC
                machining. With a degree in mechanical engineering, Kyler gained
                valuable industry experience before venturing into
                entrepreneurship. After a few years in the field, he launched
                his own machine shop, demonstrating a keen ability to blend
                precision engineering with business acumen, and hopes making
                significant contributions to the industry through the business.
              </p>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  </div>
)

const AboutFacilities = () => (
  <Row id="facilities">
    <Col>
      <div className="about-video-container">
        <video
          playsInline
          autoPlay
          muted
          loop
          id="facilities-video"
          className="about-video"
        >
          <source src={indicator} type="video/mp4" />
        </video>
      </div>
      <div id="facilities-header" className="about-video-overlay-header">
        <h1>Our Facilities</h1>
      </div>
      <div id="facilities-section" className="about-section">
        <Row xs={1} sm={1} md={1} lg={2}>
          <Col className="about-section-header">
            <h1>Our Shop</h1>
            <Image
              id="facilities-img"
              className="about-section-img"
              src={shopfloor}
              alt="Tough Cut Shop Floor"
              fluid
            />
          </Col>
          <Col className="about-section-content">
            <p align="justify">
              Located in Anaheim's Canyon, Center for Innovation Industrial
              Community, Tough Cut Manufacturing operates from state-of-the-art
              facilities. Our strategic location provides easy access to major
              freeways, facilitating efficient transportation and close
              proximity to potential commercial clients. Within our modern
              workspace, equipped with cutting-edge technologies, our highly
              skilled team delivers exceptional results while adhering to the
              highest industry standards. With a commitment to innovation and
              collaboration, we leverage our prime Southern California location
              to serve as a trusted partner in the aerospace manufacturing
              sector.
            </p>
          </Col>
        </Row>
      </div>
    </Col>
  </Row>
)

const complianceStandards = ["AS9100", "ISO 9001", "ITAR"]

const AboutStandards = () => (
  <div id="standards-section" className="about-section">
    <Row id="standards">
      <h1 id="about-standards-header">Our Standards and Practices</h1>
      <Row className="about-standards-row">
        <h2>Compliance Standards</h2>
        <div>
          Tough Cut Manufcaturing conducts operations in compliance with the
          following standards:
        </div>
        <div id="about-compliance-cards">
          {complianceStandards.map((standard) => (
            <Card bg="secondary" className="about-compliance-card">
              <Card.Body>
                <h3>{standard}</h3>
              </Card.Body>
            </Card>
          ))}
        </div>
      </Row>
      <Row className="about-standards-row">
        <h2>Quality Standards</h2>
        <p align="justify">
          Tough Cut MFG is committed to achieving all of our customers' outlined
          requirements. Implemented is a technical staff of engineers executing
          as well as improving the strength of our quality management system.
        </p>
        <p align="justify">
          Ensuring all parts produced are within the tolerance and dimensions
          desired by the client is a key step in Tough Cut's production process.
          We verify and validate the quality of our parts by reviewing parts
          through our qualification process to ensure that any parts that do not
          meet our customers standards are removed from the deliverables units.
          Our qualification department is bolstered by knowledgeable employees
          and a variety of metrology tools, including but not limited to:
        </p>
      </Row>
      <Row className="about-standards-row">
        <h2>Control Qualification Study (CQL)</h2>
        <p align="justify">
          Our CQL consists of a procedure created to ensure correct dimensions
          and specifications requests for any given part. These procedures
          include the use of metrology instrumentation by trained engineers to
          inspect all produced parts and confirm achievement of all requirements
          outlined by our customers.
        </p>
      </Row>
      <Row className="about-standards-row">
        <h2>Post Processing Services</h2>
        <p align="justify">
          Once a 3D printed part has been made, it can be post processed by
          undergoing milling. Without any post processing, our 3D prints achieve
          tolerances of up to 0.1mm, however tighter tolerances and finishes can
          be achieved by milling 3D printed parts and bringing them into
          dimension properties.
        </p>
      </Row>
    </Row>
  </div>
)

const About = () => {
  useEffect(() => {
    const { hash } = window.location
    console.log(hash)
    const hashEl = document.getElementById(hash.replace("#", ""))
    console.log(hashEl)
    if (hashEl) {
      hashEl.scrollIntoView({ behavior: "smooth" })
    }
  }, [])

  return (
    <Container fluid id="about-page">
      <AboutOverview />
      <AboutTeam />
      <AboutFacilities />
      <AboutStandards />
      <Row>
        <TCFooter pageId="about-page" />
      </Row>
    </Container>
  )
}

export default About
