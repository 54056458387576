import { Routes, Route } from "react-router-dom"
import Home from "./Pages/Home"
import About from "./Pages/About"
import Services from "./Pages/Services"
import TCNavbar from "./Utils/TCNavbar"

import "./css/app.css"

function App() {
  return (
    <>
      <TCNavbar />
      <Routes>
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </>
  )
}

export default App
