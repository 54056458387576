import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Card from "react-bootstrap/Card"

import TCFooter from "../Utils/TCFooter"

import print from "../video/3d_printing.mp4"
import turning from "../video/turning.mp4"

import loading from "../img/loading.png"
import st10Transparent from "../img/Haas ST-10 cropped.jpeg"
import sl30 from "../img/sl30.png"
import vf2Transparent from "../img/Haas VF-2 cropped.png"
import vf1Transparent from "../img/VF1-Haas-VF1.png"
import kitamuraTransparent from "../img/Kitamura MyCenter 0 - cropped.png"
import prototrak1630 from "../img/prototrak 1630rx.jpg"
import prototrak1440 from "../img/Prototrak-1440ex.jpg"
import funmat from "../img/Funmat-ht.png"

import "../css/services.css"

const mills = [
  {
    img: vf1Transparent,
    name: "Haas VF-1",
    info: `The Haas VF-1 is a small footprint, high accuracy, high performance CNC mill.`,
  },
  {
    img: vf2Transparent,
    name: "Haas VF-2",
    info: `The Haas VF-2 is a medium sized, high performance CNC mill. 
    It has a 30” x 16” x 20” max capacity, a 30” x 16” table, and a 24” x 16” x 20” max work envelope.`,
    link: "https://www.haascnc.com/machines/vertical-mills/vf-series/models/small/vf-2.html",
  },
  {
    img: kitamuraTransparent,
    name: "Kitamura MyCenter 0",
    info: `The Kitamura MyCenter 0 is a small footprint, high accuracy, high performance CNC mill.`,
  },
]

const lathes = [
  {
    img: st10Transparent,
    name: "Haas ST-10",
    info: `The Haas ST-10 is a small footprint, high accuracy, high performance CNC lathe. 
        It has a 14” x 14” max capacity, a 16” swing, and 14” max turning diameter.`,
    link: "https://www.haascnc.com/machines/lathes/st/models/standard/st-10.html",
  },
  {
    img: sl30,
    name: "Haas SL-30",
    info: `The Haas SL-30 is a medium footprint, high accuracy, high performance CNC lathe.`,
  },
  {
    img: prototrak1630,
    name: "ProtoTRAK 1630RX",
    info: `The ProtoTRAK 1630RX is a small footprint, high accuracy, high performance CNC lathe.`,
  },
  {
    img: prototrak1440,
    name: "ProtoTRAK 1440EX",
    info: `The ProtoTRAK 1440EX is a small footprint, high accuracy, high performance CNC lathe.`,
  },
]

const printers = [
  {
    img: funmat,
    name: "Intamsys Funmat HT",
    info: `The Intamsys Funmat HT is a high temperature 3D printer capable of printing PEEK, PEKK, and ULTEM.`,
    link: "https://www.intamsys.com/funmat-ht-3d-printer/",
  },
]

const materials = {
  cnc: [
    "Aluminum",
    "Copper",
    "Brass",
    "Mild Steel",
    "Cast Iron",
    "Bronze",
    "Tool Steel",
    "Stainless Steel",
    "Titanium",
    "Hardened Steel",
  ],

  printing: [
    "PLA",
    "ABS",
    "PETG",
    "TPE",
    "Nylon",
    "Carbon Fiber",
    "Polycarbonate",
    "Wood-Filled PLA",
    "Metal-Filled PLA",
    "Biofila",
    "Ceramics & Clay",
    "PEEK",
    "PEKK",
    "ULTEM",
    "",
  ],
}

const ServicesOverview = () => (
  <>
    <Row id="services-page-banner">
      <img
        id="services-page-banner-image"
        src={loading}
        alt="Tough Cut ST-10"
      />
      <h1 id="services-page-banner-title">Services</h1>
    </Row>

    <Row id="services-overview-body">
      <Row>
        <Col xs={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }}>
          <h1>Tough Cut Service Offerings</h1>
          <p align="center">
            <strong>Tough Cut Manufacturing</strong> offers a comprehensive
            range of cutting-edge services to cater to the diverse needs of the
            our industry partners. With a strong focus on precision,
            reliability, and innovation we specialize in three key areas.
          </p>
          <p>Click to learn more about each of our service offerings.</p>
        </Col>
      </Row>
      <Row id="service-overview-buttons">
        <Col className="service-overview-button-col" sm={12} md={6}>
          <Button
            className="service-overview-button"
            href="/services/#cnc-manufacturing"
          >
            <div>CNC Manufacturing</div>
          </Button>
        </Col>
        <Col className="service-overview-button-col" sm={12} md={6}>
          <Button
            className="service-overview-button"
            href="/services/#printing"
          >
            <div>3D Printing</div>
          </Button>
        </Col>
      </Row>
    </Row>
  </>
)

const ServicesCNCManufacturing = () => (
  <>
    <Row>
      <Col>
        <div className="services-video-container">
          <video
            id="cnc-manufacturing"
            playsInline
            autoPlay
            muted
            loop
            className="services-video"
          >
            <source src={turning} type="video/mp4" />
          </video>

          <div className="services-video-overlay-header">
            <h1>CNC Manufacturing</h1>
          </div>
        </div>
      </Col>
    </Row>

    <Row className="services-info">
      <Row>
        <Col xs={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }}>
          <h2>CNC Manufacturing at Tough Cut</h2>
          <p id="services-cnc-summary" align="justiy">
            Tough Cut Manufacturing is a leader in CNC machining services,
            providing precise and reliable manufacturing solutions for the
            aerospace industry. With state-of-the-art CNC machinery and a highly
            skilled team, they excel in producing intricate and accurate
            aerospace components. Leveraging advanced computer-controlled
            technology, Tough Cut ensures the highest level of precision,
            efficiency, and quality in every project. From complex engine parts
            to intricate structural components, their CNC manufacturing services
            meet the stringent requirements of the aerospace sector. With a
            commitment to excellence and innovation, Tough Cut is the trusted
            choice for aerospace companies seeking top-notch CNC machining
            expertise.
          </p>
        </Col>
      </Row>

      <div style={{ marginLeft: "5%", marginTop: "3%" }}>
        <h3>Mills</h3>
      </div>

      <Row className="service-card-row">
        {mills.map((mill) => (
          <Col key={mill.name} xs={10} sm={8} md={6} lg={3}>
            <Card className="service-card">
              <Card.Img src={mill.img} />
              <hr />
              <Card.Body className="service-card-body">
                <Card.Title className="service-card-title">
                  <h4>{mill.name}</h4>
                </Card.Title>
                <hr />
                <Card.Text>{mill.info}</Card.Text>
                {mill.link && (
                  <Button
                    href={mill.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn More
                  </Button>
                )}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      <div style={{ marginLeft: "5%", marginTop: "2%" }}>
        <h3>Lathes</h3>
      </div>

      <Row className="service-card-row">
        {lathes.map((lathe) => (
          <Col key={lathe.name} xs={10} sm={8} md={6} lg={3}>
            <Card className="service-card">
              <Card.Img src={lathe.img} />
              <hr />
              <Card.Body className="service-card-body">
                <Card.Title className="service-card-title">
                  <h4>{lathe.name}</h4>
                </Card.Title>
                <hr />
                <Card.Text>{lathe.info}</Card.Text>
                {lathe.link && (
                  <Button
                    href={lathe.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn More
                  </Button>
                )}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <div style={{ marginLeft: "5%", marginTop: "2%" }}>
        <h3>Materials</h3>
      </div>
      <Row className="service-material-card-row">
        {materials.cnc.map((material) => (
          <Col
            className="service-material-card-col"
            key={material}
            xs={{ span: 12, offset: 2 }}
            sm={{ span: 4, offset: 1 }}
            md={{ span: 4, offset: 1 }}
            lg={{ span: 3, offset: 1 }}
          >
            <Card bg="secondary" className="service-material-card">
              <Card.Body>
                <h4>{material}</h4>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Row>
  </>
)

const Services3DPrinting = () => (
  <>
    <Row>
      <Col>
        <div className="services-video-container">
          <video
            id="printing"
            playsInline
            autoPlay
            muted
            loop
            className="services-video"
          >
            <source src={print} type="video/mp4" />
          </video>
          <div className="services-video-overlay-header">
            <h1>3D Printing</h1>
          </div>
        </div>
      </Col>
    </Row>

    <Row className="services-info">
      <Row>
        <Col xs={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }}>
          <h2>3D Printing at Tough Cut</h2>
          <p id="services-printing-summary" align="justiy">
            Tough Cut offers FDM (Fused Deposition Filament) also known as FFF
            (Fused Filament Fabrication), it is the most cost effective method
            of any other additive manufacturing process. It has an extensive use
            of materials listed below which allow for either prototypes or
            functional organic parts to be made without the extensive cost of
            purchasing blocks of materials and having them subtractively
            manufactured. The Benefits of FDM are cost effectiveness, rapidness
            in turn around time, as well as material procurement since it is the
            most available source of 3D printing plastic. You can choose between
            a coarse finish inorder to acquire a part quicker and test
            functionality or order with more aesthetics in mind and have a much
            finer print with great looks and marketability at your fingertips
            within days of your order.
          </p>
        </Col>
      </Row>

      <div style={{ marginLeft: "5%", marginTop: "3%" }}>
        <h3>3D Printers</h3>
      </div>

      <Row className="service-card-row">
        {printers.map((printer) => (
          <Col key={printer.name} xs={10} sm={8} md={6} lg={3}>
            <Card className="service-card">
              <Card.Img src={printer.img} />
              <hr />
              <Card.Body className="service-card-body">
                <Card.Title className="service-card-title">
                  <h4>{printer.name}</h4>
                </Card.Title>
                <hr />
                <Card.Text>{printer.info}</Card.Text>
                {printer.link && (
                  <Button
                    href={printer.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn More
                  </Button>
                )}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      <div style={{ marginLeft: "5%", marginTop: "2%" }}>
        <h3>Materials</h3>
      </div>
      <Row className="service-material-card-row">
        {materials.printing.map((material) => (
          <Col
            className="service-material-card-col"
            key={material}
            xs={{ span: 12, offset: 2 }}
            sm={{ span: 4, offset: 1 }}
            md={{ span: 4, offset: 1 }}
            lg={{ span: 3, offset: 1 }}
          >
            <Card
              bg="secondary"
              className={`service-material-card${material ? "" : "-hidden"}`}
            >
              <Card.Body>
                <h4>{material}</h4>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Row>
  </>
)

const Services = () => (
  <Container id="services-page" fluid>
    <ServicesOverview />
    <ServicesCNCManufacturing />
    <Services3DPrinting />
    <TCFooter pageId="services-page" />
  </Container>
)

export default Services
