import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import Container from "react-bootstrap/Container"
import Image from "react-bootstrap/Image"
import Nav from "react-bootstrap/Nav"
import NavDropdown from "react-bootstrap/NavDropdown"
import Navbar from "react-bootstrap/Navbar"
import Offcanvas from "react-bootstrap/Offcanvas"

import TLogoTransparent from "../img/tc-t-transparent.png"
import TCLogoTransparent from "../img/tc-logo-transparent.png"
import "../css/navigation.css"

const TCNavbar = () => {
  const { pathname, hash } = useLocation()
  useEffect(() => {
    if (hash) {
      document.querySelector(hash).scrollIntoView(true)
    }
  }, [hash])

  return (
    <Navbar id="tc-navbar" expand="lg" fixed="top">
      <Container fluid>
        <Navbar.Brand href="/" id="tc-navbar-brand">
          <img src={TCLogoTransparent} alt="Tough Cut Logo" width="200vw" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-expand-sm" />
        <Navbar.Offcanvas
          id="navbar-expand-sm"
          aria-labelledby="navbar-label-expand-sm"
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Image
              src={TLogoTransparent}
              fluid
              style={{ maxWidth: "35%", marginLeft: "30%" }}
            />
          </Offcanvas.Header>
          <Offcanvas.Body id="tc-navbar-offcanvas-body">
            <Nav
              id="tc-nav"
              activeKey={hash === "#contact-info" ? "/#contact-info" : pathname}
              fill
            >
              <Nav.Link href="/" className="tc-nav-item">
                Home
              </Nav.Link>
              <Nav.Link href="/about" className="tc-nav-item">
                About
              </Nav.Link>
              <NavDropdown
                title="Services"
                eventKey="/services"
                active={pathname === "/services"}
                className="tc-nav-item"
              >
                <NavDropdown.Item
                  className="tc-nav-item"
                  as={Nav.Link}
                  href="/services"
                >
                  Overview
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="tc-nav-item"
                  as={Nav.Link}
                  href="/services/#cnc-manufacturing"
                >
                  CNC Manufacturing
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="tc-nav-item"
                  as={Nav.Link}
                  href="/services/#printing"
                >
                  3D Printing
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link className="tc-nav-item" href="/#contact">
                Contact
              </Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  )
}

export default TCNavbar
